/* eslint-disable no-useless-escape */
/* eslint-disable  no-unused-expressions */
import React from 'react';
import styles from './BitaInput.module.scss';

const BitaInput = props => {
  const {
    handleChange,
    value = '',
    type,
    labelinput,
    id,
    externalTitle,
    postText,
    placeholder,
    text,
    maxlength,
    onBlur,
    showAsLabel,
    justify,
    tooltip,
    upper_limit,
    lower_limit,
    typeoutput,
    row_style,
    onKeyPress,
    autoFocus,
    validation,
    typeTime,
    ref,
  } = props;

  const numberWithCommas = n => {
    n = n.toString();
    const auxFloat = n.indexOf('.');
    const aux = n.replace(/,/g, '');
    const parts = aux.toString().split('.');
    return (
      Number(parts[0])
        .toString()
        .replace(/[^0-9-]/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      (parts[1] ? `.${parts[1].replace(/[^0-9]/g, '')}` : auxFloat > 0 ? '.' : '')
    );
  };

  const handleOnChange = newValue => {
    if (handleChange) {
      // let _value = newValue;
      // if (porcentage) {
      //   _value = minMaxTruncation(newValue, 0, 100);
      // }

      const onlyNumber = n => {
        n = n.toString();
        const auxFloat = n.indexOf('.');
        const aux = n.replace(/,/g, '');
        const parts = aux.toString().split('.');
        return (
          parts[0].replace(/[^0-9-]/g, '') +
          (parts[1] ? `.${parts[1].replace(/[^0-9]/g, '')}` : auxFloat > 0 ? '.' : '')
        );
      };

      const validateNegativeLowerLimit = () => {
        if (lower_limit >= 0) {
          return parseFloat(`${newValue}9`) >= lower_limit;
        }

        return parseFloat(newValue) >= lower_limit;
      };

      if (type === 'number') {
        if (newValue === '-' && lower_limit < 0) {
          handleChange(newValue);
          return;
        }
        if (
          upper_limit &&
          !lower_limit &&
          (parseFloat(newValue) <= upper_limit || newValue === '-' || newValue === '')
        ) {
          typeoutput === 'simple'
            ? handleChange(onlyNumber(newValue))
            : handleChange(numberWithCommas(newValue));
        }
        if (
          lower_limit &&
          !upper_limit &&
          (parseFloat(newValue) >= lower_limit || newValue === '-' || newValue === '')
        ) {
          typeoutput === 'simple'
            ? handleChange(onlyNumber(newValue))
            : handleChange(numberWithCommas(newValue));
        }
        if (
          lower_limit &&
          upper_limit &&
          ((validateNegativeLowerLimit() && parseFloat(newValue) <= upper_limit) ||
            newValue === '-' ||
            newValue === '' ||
            (lower_limit < 1 && parseFloat(newValue) === 0))
        ) {
          typeoutput === 'simple'
            ? handleChange(onlyNumber(newValue))
            : handleChange(numberWithCommas(newValue));
        }
        if (!upper_limit && !lower_limit) {
          typeoutput === 'simple'
            ? handleChange(onlyNumber(newValue))
            : handleChange(numberWithCommas(newValue));
        }
      } else if (type === 'percentage') {
        if (newValue >= 0 && newValue <= 100) {
          handleChange(newValue.replace(/[^\d\.]/g, ''));
        }
      } else if (type === 'integer') {
        if (newValue === '' || (newValue !== '-' && (newValue >= lower_limit || !lower_limit))) {
          const regex = /([.]+)/;
          const negativeAfter = /\d[-]/;
          if (!regex.test(newValue) && !negativeAfter.test(newValue)) {
            handleChange(numberWithCommas(newValue));
          }
        }
      } else if (type === 'percentage_limit') {
        if (
          newValue === '' ||
          (lower_limit &&
            upper_limit &&
            parseFloat(newValue) >= lower_limit &&
            parseFloat(newValue) <= upper_limit)
        ) {
          typeoutput === 'simple'
            ? handleChange(onlyNumber(newValue))
            : handleChange(numberWithCommas(newValue));
        }
      } else if (validation) {
        const regex = new RegExp(validation);
        if (regex.test(newValue)) {
          handleChange(newValue);
        }
      } else {
        handleChange(newValue);
      }
    }
  };

  return (
    <div
      className={`${styles.bitaInput} ${props.inputContainerStyle}`}
      style={{ marginLeft: justify && justify === 'flex-start' ? '0px' : 'auto' }}
    >
      <div className={`${styles.column}`}>
        {externalTitle ? (
          <span className={`${props.externalTitleStyle}`}>{externalTitle}</span>
        ) : null}
        <div className={`${styles.row} inputrow `}>
          {text ? <span className={`${styles.text} inputtext`}>{text}</span> : null}
          <input
            ref={ref}
            id={id}
            placeholder={placeholder}
            onKeyPress={onKeyPress}
            autoFocus={autoFocus}
            value={type === 'number' && typeoutput === 'simple' ? numberWithCommas(value) : value}
            className={
              showAsLabel
                ? `${props.inputStyle} inputStyle ${styles.bitaInputLabel} ${
                    row_style ? styles.row_input_style : ''
                  }`
                : `${props.inputStyle} inputStyle ${row_style ? styles.row_input_style : ''}`
            }
            type={typeTime || 'text'}
            onChange={evt => {
              handleOnChange(evt.target.value);
            }}
            maxLength={maxlength}
            onBlur={() => {
              if (lower_limit && lower_limit > value) {
                handleOnChange(lower_limit);
              }
              if (onBlur) {
                onBlur();
              }
            }}
            disabled={showAsLabel}
          />
          {labelinput ? (
            <label
              id={id}
              style={
                // eslint-disable-next-line
                value === ''
                  ? text
                    ? { right: '50px' }
                    : null
                  : text
                  ? { right: '50px', top: '-17px' }
                  : { top: '-17px' }
              }
              htmlFor={id}
            >
              {labelinput}
            </label>
          ) : null}
          {postText ? <span className={`${styles.posttext} inputPostText`}>{postText}</span> : null}
          {tooltip && <span className={styles.tooltip}>{tooltip}</span>}
        </div>
      </div>
    </div>
  );
};

export default BitaInput;
