/* eslint-disable */

// Necestio deshabilitar ESLINT aca porque da problemas con el label.
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

const BitaCheckbox = props => {
  const {
    label,
    handleChange,
    onClick,
    separation,
    value,
    isChecked,
    checkboxUpdate,
    labelClassName = '',
    disabled,
  } = props;
  const [checked, setCheckedState] = useState(false);
  const [checkId] = useState(uniqueId('bitacheckbox-'));
  const onChangeCheckbox = evt => {
    setCheckedState(evt?.target?.checked);
    handleChange ? handleChange({ id: label, value: evt?.target?.checked }) : {};
  };

  useEffect(() => {
    setCheckedState(isChecked);
    handleChange({ id: label, value: isChecked });
  }, [isChecked]);

  return (
    <div
      style={{ background: checked ? '#6496EE' : ' ', opacity: disabled ? '0.5' : '' }}
      className="multiselectCheckbox"
    >
      <input
        type="checkbox"
        disabled={disabled}
        className="css-checkbox"
        id={checkId}
        style={{ marginRight: separation }}
        onChange={onChangeCheckbox}
        checked={checked}
        onClick={onClick}
      />
      <label className={`css-label ${labelClassName}`} htmlFor={checkId}>
        {label}
      </label>
      <div>({value}) </div>
    </div>
  );
};

BitaCheckbox.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func,
  separation: PropTypes.number,
};

BitaCheckbox.defaultProps = {
  label: '',
  handleChange: () => null,
  separation: 5,
};

export default BitaCheckbox;
