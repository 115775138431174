const DATA_LIST_DROPDOWN = [
  { value: 'Long only Basket', id: 'basket', disabled: false },
  // { value: 'Bottom-Up Basket', id: 'global', isDisabled: true },
  { value: 'Long/Short', id: 'long/short', disabled: true },
];

const BASKET_BUTTON_BULK = {
  'Long only Basket': [
    {
      type: 'Button',
      label: 'Submission with Weights',
      url: 'divisor_basket_input_with_open_weight',
    },
    {
      type: 'Button',
      label: 'Submission with Shares',
      url: 'divisor_basket_input_with_shares_and_without_custom_prices',
    },
    {
      type: 'Button',
      label: 'Submission with Notionals',
      url: 'divisor_basket_input_with_divisor_and_notional',
    },
  ],
  'Bottom-Up Basket': [{ type: 'Button', label: 'Global Template', url: 'global_input' }],
  'Long/Short': [{ type: 'Button', label: 'Long/short Template', url: 'long_short_input' }],
};

const basketButton = {
  'Long only Basket': [
    {
      type: 'Button',
      label: 'Submission with Weights',
      url: 'divisor_basket_input_with_open_weight',
    },
    {
      type: 'Button',
      label: 'Submission with Shares',
      url: 'divisor_basket_input_with_shares_and_without_custom_prices',
    },
    {
      type: 'Button',
      label: 'Submission with Notionals',
      url: 'divisor_basket_input_with_divisor_and_notional',
    },
  ],
  'Bottom-Up Basket': [{ type: 'Button', label: 'Global Template', url: 'global_input' }],
  'Long/Short': [{ type: 'Button', label: 'Long/short Template', url: 'long_short_input' }],
};

const CURRENCIES_ARRAY = [
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'CNH',
  'CNY',
  'COP',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'ILA',
  'ILS',
  'JPY',
  'KRW',
  'MXN',
  'NGN',
  'NOK',
  'PLN',
  'RUB',
  'SEK',
  'SGD',
  'TRY',
  'TWD',
  'USD',
  'XAG',
  'XAL',
  'XAU',
  'XCU',
  'XNI',
  'XPB',
  'XPD',
  'XPT',
  'XZN',
];

const BUSINESS_DAYS = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday',
};

const DATA_APPLICATION_POINT = [
  {
    value: 'At market close',
    id: 'AT_MARKET_CLOSE',
  },
  {
    value: 'At market open',
    id: 'AT_MARKET_OPEN',
  },
];

const DATA_CASH_DISTRIBUTION = [
  {
    value: 'Reinvest distributions across basket',
    id: 'DISTRIBUTION_ACROSS_BASKET',
  },
  {
    value: 'Reinvest distributions on paying security',
    id: 'DISTRIBUTION_ON_PAYING_SECURITY',
  },
  { value: 'Add distributions to cash', id: 'ADD_DISTRIBUTION_TO_CASH' },
];

const DATA_DIVISOR_DETERMINATION_SETUP = [
  { value: 'PREVIOUS EOD', id: 'PREVIOUS_EOD' },
  { value: 'IMMEDIATELY', id: 'IMMEDIATELY' },
];

const HEADERSIZEINITIAL = {
  'All select': 30,
  isin: 160,
  sedol: 90,
  ticker: 90,
  type: 60,
  shares: 150,
  weights: 180,
  awf: 40,
  iwf: 40,
  exchange: 150,
};
const SHARES_DETERMINATION_POINT = {
  IMMEDIATELY: 'IMMEDIATELY',
  PREVIOUS_EOD: 'PREVIOUS EOD',
};

const ORDERED_COLUMNS_WITH_VALID_UBS = [
  'All select',
  'sedol',
  'isin',
  'ticker',
  'company_name',
  'exchange',
  'awf',
  'iwf',
  'status',
];

const ORDERED_COLUMNS_WITHOUT_VALID_UBS = [
  'All select',
  'isin',
  'sedol',
  'ticker',
  'company_name',
  'exchange',
  'awf',
  'iwf',
  'status',
];

const VISIBILITY_INITIAL = {
  'All select': true,
  isin: true,
  awf: false,
  iwf: false,
  shares: true,
  exchange: true,
  sedol: true,
  company_name: true,
  ticker: true,
  weights: true,
  type: true,
};

const DATA_DISSEMINATION = [
  { value: 'BITA Only', id: 'bita_only' },
  { value: 'BITA + Bloomberg', id: 'bita_bloomberg' },
];

const DATA_COUNTRY_CODE = [
  'AD',
  'AE',
  'AF',
  'AG',
  'AI',
  'AL',
  'AM',
  'AN',
  'AO',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AW',
  'AX',
  'AZ',
  'BA',
  'BB',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BL',
  'BM',
  'BN',
  'BO',
  'BQ',
  'BR',
  'BS',
  'BT',
  'BV',
  'BW',
  'BY',
  'BZ',
  'CA',
  'CC',
  'CD',
  'CF',
  'CG',
  'CH',
  'CI',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CR',
  'CU',
  'CV',
  'CW',
  'CX',
  'CY',
  'CZ',
  'DE',
  'DJ',
  'DK',
  'DM',
  'DO',
  'DZ',
  'EC',
  'EE',
  'EG',
  'EH',
  'ER',
  'ES',
  'ET',
  'FI',
  'FJ',
  'FK',
  'FM',
  'FO',
  'FR',
  'GA',
  'GB',
  'GD',
  'GE',
  'GF',
  'GG',
  'GH',
  'GI',
  'GL',
  'GM',
  'GN',
  'GP',
  'GQ',
  'GR',
  'GS',
  'GT',
  'GU',
  'GW',
  'GY',
  'HK',
  'HM',
  'HN',
  'HR',
  'HT',
  'HU',
  'ID',
  'IE',
  'IL',
  'IM',
  'IN',
  'IO',
  'IQ',
  'IR',
  'IS',
  'IT',
  'JE',
  'JM',
  'JO',
  'JP',
  'KE',
  'KG',
  'KH',
  'KI',
  'KM',
  'KN',
  'KP',
  'KR',
  'KW',
  'KY',
  'KZ',
  'LA',
  'LB',
  'LC',
  'LI',
  'LK',
  'LR',
  'LS',
  'LT',
  'LU',
  'LV',
  'LY',
  'MA',
  'MC',
  'MD',
  'ME',
  'MF',
  'MG',
  'MH',
  'MK',
  'ML',
  'MM',
  'MN',
  'MO',
  'MP',
  'MQ',
  'MR',
  'MS',
  'MT',
  'MU',
  'MV',
  'MW',
  'MX',
  'MY',
  'MZ',
  'NA',
  'NC',
  'NE',
  'NF',
  'NG',
  'NI',
  'NL',
  'NO',
  'NP',
  'NR',
  'NU',
  'NZ',
  'OM',
  'PA',
  'PE',
  'PF',
  'PG',
  'PH',
  'PK',
  'PL',
  'PM',
  'PN',
  'PR',
  'PS',
  'PT',
  'PW',
  'PY',
  'PZ',
  'QA',
  'RE',
  'RO',
  'RS',
  'RU',
  'RW',
  'SA',
  'SB',
  'SC',
  'SD',
  'SE',
  'SG',
  'SH',
  'SI',
  'SJ',
  'SK',
  'SL',
  'SM',
  'SN',
  'SO',
  'SR',
  'SS',
  'ST',
  'SV',
  'SX',
  'SY',
  'SZ',
  'TC',
  'TD',
  'TF',
  'TG',
  'TH',
  'TJ',
  'TK',
  'TL',
  'TM',
  'TN',
  'TO',
  'TR',
  'TT',
  'TV',
  'TW',
  'TZ',
  'UA',
  'UG',
  'UM',
  'US',
  'UY',
  'UZ',
  'VA',
  'VC',
  'VE',
  'VG',
  'VI',
  'VN',
  'VU',
  'WF',
  'WS',
  'XK',
  'YE',
  'YT',
  'ZA',
  'ZB',
  'ZM',
  'ZW',
];
const GEOGRAPHICAL_REGION = [
  '001',
  '002',
  '005',
  '009',
  '011',
  '013',
  '014',
  '015',
  '017',
  '018',
  '019',
  '021',
  '029',
  '030',
  '034',
  '035',
  '039',
  '053',
  '054',
  '057',
  '061',
  '142',
  '143',
  '145',
  '150',
  '151',
  '154',
  '155',
  '202',
  '419',
  '830',
];

const GEOGRAPHICAL_REGION_NAMES = {
  '002': 'Africa',
  '019': 'Americas',
  '142': 'Asia',
  '053': 'Australia and New Zealand',
  '029': 'Caribbean',
  '013': 'Central America',
  '143': 'Central Asia',
  '830': 'Channel Islands',
  '014': 'Eastern Africa',
  '030': 'Eastern Asia',
  '151': 'Eastern Europe',
  '150': 'Europe',
  '419': 'Latin America and the Caribbean',
  '054': 'Melanesia',
  '057': 'Micronesia',
  '017': 'Middle Africa',
  '015': 'Northern Africa',
  '021': 'Northern America',
  '154': 'Northern Europe',
  '009': 'Oceania',
  '061': 'Polynesia',
  '005': 'South America',
  '035': 'South-Eastern',
  '018': 'Southern Africa',
  '034': 'Southern Asia',
  '039': 'Southern Europe',
  '202': 'Sub-Saharan Africa',
  '011': 'Western Africa',
  '145': 'Western Asia',
  '155': 'Western Europe',
  '001': 'World',
};

const TIMEZONE_OF_TRADING_HOURS = [
  'EASTERN_STANDARD_TIME',
  'CENTRAL_STANDARD_TIME',
  'MOUNTAIN_STANDARD_TIME',
  'PACIFIC_STANDARD_TIME',
  'BRAZIL_TIME',
  'GREENWICH_MEAN_TIME',
  'UNIVERSAL_TIME',
  'CENTRAL_EUROPEAN_TIME',
  'ARABIA_STANDARD_TIME',
  'GULF_STANDARD_TIME',
  'CHINA_STANDARD_TIME',
  'JAPAN_STANDARD_TIME',
  'AUSTRALIA_EASTERN_STANDARD_TIME',
  'INDIA_STANDARD_TIME',
];
const TRADING_DAYS = [
  'MON_TO_FRI',
  'SUN_TO_THU',
  'TUE_TO_SAT',
  'MON_TO_SUN',
  'MON_TO_SAT',
  'MON_IF_NON_DAILY',
  'TUE_IF_NON_DAILY',
  'WED_IF_NON_DAILY',
  'THU_IF_NON_DAILY',
  'FRI_IF_NON_DAILY',
  'SAT_IF_NON_DAILY',
  'SUN_IF_NON_DAILY',
  'OTHER',
];

export {
  DATA_COUNTRY_CODE,
  GEOGRAPHICAL_REGION,
  GEOGRAPHICAL_REGION_NAMES,
  TIMEZONE_OF_TRADING_HOURS,
  TRADING_DAYS,
  HEADERSIZEINITIAL,
  VISIBILITY_INITIAL,
  DATA_DISSEMINATION,
  ORDERED_COLUMNS_WITH_VALID_UBS,
  ORDERED_COLUMNS_WITHOUT_VALID_UBS,
  DATA_LIST_DROPDOWN,
  BASKET_BUTTON_BULK,
  CURRENCIES_ARRAY,
  BUSINESS_DAYS,
  DATA_APPLICATION_POINT,
  DATA_CASH_DISTRIBUTION,
  DATA_DIVISOR_DETERMINATION_SETUP,
  SHARES_DETERMINATION_POINT,
};
