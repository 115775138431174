import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import 'simplebar';
import Papa from 'papaparse';
import { get, post } from 'axios';
import 'simplebar/dist/simplebar.css';
import FileSaver from 'file-saver';
import { BiSolidError, BiSolidInfoCircle, BiX, BiCheck } from 'react-icons/bi';
import { FaCheckSquare } from 'react-icons/fa';
import Table from './Components/EditTable';
import BitaCheckbox from '../../components/BitaCheckbox/BitaCheckbox';
import BitaSpinner from '../../components/BitaSpinner/BitaSpinner';
import BitaCalendarDate from '../../components/BitaCalendar/BitaCalendarDate';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import BitaSelect from '../../components/BitaSelect/BitaSelect';
import BitaInput from '../../components/BitaInput/BitaInput';
import Switcher from '../../bitaComponents/Switcher/Switcher';
import BitaTable from '../../bitaComponents/BitaTable/BitaTable';
import BitaModal from '../../bitaComponents/BitaModal/BitaModal';
import styles from './index-builder.module.css';
import style from './PortfolioBasketCalculation.module.scss';
import { ReactComponent as Logo } from '../../static/img/Icons/BITA.svg';
import { toastInterceptor, hasValidUBSAPACOnly, hasValidBitaDomain } from '../../utils/utils';
import { LIST_TIMEZONE_LIVE_BASKET } from '../../constants/timezome-list-constants';
import { DATA_DUMMIE_BACKTEST, DUMMIE_PREFIX } from './utils/dataDummie';
import { URL_CONSTANTS } from '../../api-data-mapping/url-constants';
import {
  CURRENCIES_ARRAY,
  DATA_LIST_DROPDOWN,
  BUSINESS_DAYS,
  DATA_COUNTRY_CODE,
  GEOGRAPHICAL_REGION,
  TIMEZONE_OF_TRADING_HOURS,
  TRADING_DAYS,
  DATA_DISSEMINATION,
  DATA_DIVISOR_DETERMINATION_SETUP,
  GEOGRAPHICAL_REGION_NAMES,
  SHARES_DETERMINATION_POINT,
} from './utils/constants';

import {
  createBasket,
  uploadFileSettings,
  coverageRequest,
  consultExistBasket,
  uploadBacktest,
} from './utils/api';
import VisualizationModal from './withholding-tax/VisualizationModal';
import { AddInstrumentModal } from './Modals/AddInstrumentModal';
import { existRequestCoverageWithData, orderByType } from './utils';
import ShowPreview from './utils/ShowPreview';

import './Portfolio.css';

const getMIC = (tokenUser, data) => {
  return get(URL_CONSTANTS.ACE_MIC_ALL_URL, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

const recalculateCoverage = (tokenUser, data) => {
  return post(URL_CONSTANTS.COVERAGE_REQUEST_UPDATE_URL, data, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

const basketButton = {
  'Long only Basket': [
    {
      type: 'Button',
      label: 'Submission with Weights',
      url: 'divisor_basket_input_with_open_weight',
    },
    {
      type: 'Button',
      label: 'Submission with Shares',
      url: 'divisor_basket_input_with_shares_and_without_custom_prices',
    },
    {
      type: 'Button',
      label: 'Submission with Notionals',
      url: 'divisor_basket_input_with_divisor_and_notional',
    },
  ],
  'Bottom-Up Basket': [{ type: 'Button', label: 'Global Template', url: 'global_input' }],
  'Long/Short': [{ type: 'Button', label: 'Long/short Template', url: 'long_short_input' }],
};
const timezone = LIST_TIMEZONE_LIVE_BASKET.sort().map(item => {
  return {
    value: item.region,
    id: item.region,
    name: item.region,
    timezone: item.timezone,
    calculationZone: item.calculationZone,
    calculationEodTime: item.calculationEodTime,
  };
});

const styleClose = {
  position: 'absolute',
  fontSize: '30px',
  fontWeight: 'bold',
  top: '-10px',
  right: '0',
  cursor: 'pointer',
  padding: '10px',
  boxSizing: 'border-box',
};

const FONT_SIZE = { fontSize: 13 };

// TODO Function to format date to yyyy-mm-dd (improve this because is the same function of UpdateBasket)
const formatDateString = dateString => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

const isValidDateFormat = dateString => {
  let isValid = false;
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!regex.test(dateString)) {
    isValid = false;
  }

  const date = new Date(dateString);
  isValid = date.toISOString().slice(0, 10) === dateString;

  return isValid;
};

const validateData = [
  'timezone',
  'symbol',
  'calculation_setup',
  'launch_point',
  'currency',
  'calculation_type',
  'calculation_zone',
  'cash_distribution',
  'application_point',
  'dissemination',
];

const validateDataLabel = {
  timezone: 'Timezone',
  symbol: 'Symbol',
  calculation_setup: 'Calculation frequency',
  deployment_setup: 'Launch Point',
  currency: 'Currency',
  calculation_type: 'Return calculation',
  calculation_zone: 'Calculation Zone',
  cash_distribution: 'Cash Distribution',
  application_point: 'Application Point',
  dissemination: 'Dissemination',
};

const validatedFieldsValues = {
  currency: CURRENCIES_ARRAY,
  calculation_setup: ['EOD', 'EOD_INTRADAY'],
  divisor_determination_setup: ['PREVIOUS_EOD', 'IMMEDIATELY'],
  calculation_zone: ['us_only', 'us_europe', 'global', 'europe_only', 'eastern_apac'],
  launch_point: ['MARKET_OPEN', 'MARKET_CLOSE'],
  deployment_setup: ['EOD', 'IMMEDIATELY'],
  calculation_type: ['NET_RETURN', 'TOTAL_RETURN', 'PRICE_RETURN'],
  cash_distribution: [
    'DISTRIBUTION_ACROSS_BASKET',
    'DISTRIBUTION_ON_PAYING_SECURITY',
    'ADD_DISTRIBUTION_TO_CASH',
  ],
  application_point: ['AT_MARKET_CLOSE', 'AT_MARKET_OPEN'],
  dissemination: ['bita_only', 'bita_bloomberg'],
};

const DATA_APPLICATION_POINT = [
  {
    value: 'At market close',
    id: 'AT_MARKET_CLOSE',
  },
  {
    value: 'At market open',
    id: 'AT_MARKET_OPEN',
  },
];
const DATA_CASH_DISTRIBUTION = [
  {
    value: 'Reinvest distributions across basket',
    id: 'DISTRIBUTION_ACROSS_BASKET',
  },
  {
    value: 'Reinvest distributions on paying security',
    id: 'DISTRIBUTION_ON_PAYING_SECURITY',
  },
  { value: 'Add distributions to cash', id: 'ADD_DISTRIBUTION_TO_CASH' },
];

const DATA_CALCULATION_TYPE = { MARKET_OPEN: 'MARKET Open', MARKET_CLOSE: 'MARKET Close' };

const DATA_CALCULATION = [
  { value: 'MARKET_OPEN', id: 'MARKET Open' },
  { value: 'MARKET_CLOSE', id: 'MARKET Close' },
];
const MESSAGES_ALERT = {
  divisorPreviousEODLaunchImmediate: `BITACore will apply yesterday's corporate actions, calculate the opening value, and start calculating the intraday values. However, there is no guarantee that all complex corporate actions will be implemented.`,
  divisorImmediatelyLaunchImmediately: `BITACore will immediately begin calculating the basket/index without incorporating any corporate actions from last night.`,
  divisor: `As the divisor was provided by the user, BITACore won't apply any corporate actions from last night.`,
};

const INITIAL_PAGINATION = {
  pageIndex: 0,
  pageSize: 10,
};
const truncateString = (str, limit) => {
  if (str?.length > limit) {
    return `${str?.substring(0, limit)}...`;
  }
  return str;
};

// Verifies that a date is from today or later (and not a date in the past). For example: valid dates: today date, tomorrow date. Invalid dates: yesterday date, last month date, etc.
function isValidDateString(dateString) {
  // Check if the date string matches the format yyyy-mm-dd
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  // Parse the date string into a Date object
  const date = new Date(`${dateString}T00:00:00`); // Treat as local midnight
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today's date to local midnight

  // Check if the date is today or in the future
  return date >= today;
}

const SubmitBasket = () => {
  const [files, setFiles] = React.useState('');
  const [filesBacktest, setFilesBacktest] = React.useState('');
  const user = useSelector(state => state.auth);
  const authOrgPrefix = useSelector(state => state.auth?.org_prefix);
  const [type, setType] = useState('Long only Basket');
  const [data, setData] = useState({});
  const [dataInitial, setDataInitial] = useState({});
  const [launchDate, setLaunchDate] = useState(null); // Now the initial state is null instead of today date
  const [errorCalendar, setErrorCalendar] = useState(false);
  const [errorSymbol, setErrorSymbol] = useState(false);
  const [errorReferenceData, setErrorReferenceData] = useState(false);
  const [listMIC, setListMIC] = useState([]);
  const [pagination, setPagination] = useState(INITIAL_PAGINATION);
  const [filterTable, setFilterTable] = useState('');
  const [isActiveAnnualizedYearlyDecrement, setIsActiveAnnualizedYearlyDecrement] = useState(false);
  const [constituentsError, setConstituentsError] = useState([]);
  const [constituentsDelete, setConstituentsDelete] = useState([]);
  const [dataBacktest, setDataBacktest] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalRequestCoverage, setShowModalRequestCoverage] = useState(false);
  const [showModalRequestCoverageSuccessful, setShowModalRequestCoverageSuccessful] = useState(
    false,
  );
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showSedolNotFoundModal, setShowSedolNotFoundModal] = useState(false);
  const [sedolsList, setSedolsList] = useState([]);
  const [enableDivisorOrStartingValue, SetEnableDivisorOrStartingValue] = useState({
    statusDivisor: true,
    statusStarting: false,
  });
  const [buttonStateLoading, setButtonStateLoading] = useState({
    basket: false,
    backtest: false,
    submit: false,
  });
  const [templateData, setTemplateData] = useState([]);
  const [openWithholingModal, setOpenWithholdingModal] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);

  const history = useHistory();
  const inputBasketRef = useRef(null);
  const inputBacktestRef = useRef(null);
  const inputRef = useRef(null);
  const { token } = user;
  const [orgPrefix, setOrgPrefix] = useState(authOrgPrefix || '');
  const divisorPreviousEODLaunchImmediate =
    enableDivisorOrStartingValue.statusStarting &&
    data?.divisor_determination_setup === 'PREVIOUS EOD' &&
    data?.calculation_setup === 'EOD_INTRADAY' &&
    data.deployment_setup === 'IMMEDIATELY';
  const divisorImmediatelyLaunchImmediately =
    enableDivisorOrStartingValue.statusStarting &&
    data?.divisor_determination_setup === 'IMMEDIATELY' &&
    data?.calculation_setup === 'EOD_INTRADAY' &&
    data.deployment_setup === 'IMMEDIATELY';

  const getTemplates = () => {
    return get(URL_CONSTANTS.WITHHOLDING_TAX, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const handleChange = (name, value) => {
    if (name === 'calculationZone') {
      const timeData = LIST_TIMEZONE_LIVE_BASKET.find(item => item.region === value);
      setData({
        ...data,
        region: value,
        timezone: timeData?.timezone,
        calculation_close_time: timeData?.calculationEnd,
        calculation_open_time: timeData?.calculationStart,
        calculation_zone: timezone.find(item => item?.name === value)?.calculationZone,
        calculation_eod_time: timeData.calculationEodTime,
        calculationEodTime: timeData.calculationEodTime,
      });
    } else if (name === 'calculation_type' && value === 'PRICE_RETURN') {
      setData({
        ...data,
        additional_settings: {
          ...data?.additional_settings,
          cash_distribution_application_point: 'AT_MARKET_OPEN',
        },
        cash_distribution: 'DISTRIBUTION_ACROSS_BASKET',
        application_point: 'AT_MARKET_OPEN',
        [name]: value,
      });
    } else if (name === 'application_point') {
      setData({
        ...data,
        additional_settings: {
          ...data?.additional_settings,
          cash_distribution_application_point: value,
        },
        [name]: value,
      });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const cleanStates = () => {
    setShowModal(false);
    setShowModal(false);
    // setData(null);
    setFiles('');
    setFilesBacktest('');
    SetEnableDivisorOrStartingValue({
      statusDivisor: true,
      statusStarting: false,
    });

    // new states to clean
    setErrorCalendar(false);
    setLaunchDate(null);

    setSedolsList([]);
    setShowSedolNotFoundModal(false);
    setShowModalDelete(false);
    setShowModalRequestCoverageSuccessful(false);
    setShowModalRequestCoverage(false);
    setShowModalConfirm(false);
    setDataBacktest([]);
    setConstituentsDelete([]);
    setConstituentsError([]);
    setIsActiveAnnualizedYearlyDecrement(false);
    setData({});
    setType('Long only Basket');
  };

  const cleanAllStates = () => {
    cleanStates();

    // clean the refs
    if (inputBasketRef.current) {
      inputBasketRef.current.value = ''; // Basket ref
    }
    if (inputBacktestRef.current) {
      inputBacktestRef.current.value = ''; // Backtest ref
    }
  };

  const validarData = (dataSend, list) => {
    if (dataSend.calculation_setup === 'EOD') {
      list = list.filter(element => element !== 'launch_point');
    }
    return list.every(
      propertyValidate =>
        propertyValidate in dataSend &&
        typeof dataSend[propertyValidate] === 'string' &&
        dataSend[propertyValidate].trim() !== '',
    );
  };

  const validateFields = (key, field) => {
    let acceptedValues;
    if (!key || !field) return false;
    if (key === 'withholding_tax_id') {
      acceptedValues = templateData?.some(item => item.id === field);
      return acceptedValues;
    }
    acceptedValues = validatedFieldsValues[key];
    return !!acceptedValues?.includes(field);
  };

  useEffect(() => {
    getTemplates()
      .then(rsp => {
        const dataTax = [
          ...rsp?.data?.data,
          {
            withholding_tax_name: 'BITA Withholding Taxes',
            id: 'BITA Withholding Taxes',
            custom: 'false',
          },
        ];
        setTemplateData(dataTax);
      })
      .catch(error => {
        toastInterceptor(error);
      });
    getMIC(token)
      .then(rsp => {
        setListMIC(Object.values(rsp?.data?.data));
      })
      .catch(error => {
        toastInterceptor(error);
      });
  }, []);

  const BasketTable = ({ titleBasketTable, deleteBasketTable, buttonsBasketTable, scroll }) => {
    const renderTable = () => {
      if (type === 'Long only Basket' && data?.meta_reference_data) {
        const dataRows = data?.meta_reference_data ?? data.metadata ?? [];
        const columns = Object.keys(dataRows?.[0] ?? {}).filter(item => {
          if (data?.identifier_type === 'isin') {
            return item !== 'type' && item !== 'sedol';
          }
          return item !== 'type';
        });

        return (
          <div className="contentTableBasketPreview">
            {scroll ? (
              <></>
            ) : (
              <BitaTable
                id="basket"
                data={dataRows ?? []}
                height="100%"
                style={{ padding: '0' }}
                search={false}
                scroll={!scroll}
                showFields={deleteBasketTable ? [...(columns ?? [])] : columns ?? []}
                selectAction={() => {}}
                editButton={false}
                CustomRowComponent={({ data: dataValue, entryKey, value }) => (
                  <div
                    className="custom-row-container"
                    style={{ width: entryKey === 'ticker' ? '100px' : '' }}
                  >
                    <p className={`${entryKey === 'type' ? 'info-icon-live' : ''}`}>
                      {entryKey === 'type' && deleteBasketTable ? (
                        value === 'with_coverage' ? (
                          <FaCheckSquare className="icon-info" />
                        ) : (
                          <BiSolidError className="icon-error" />
                        )
                      ) : entryKey === 'isin' && deleteBasketTable ? (
                        <div style={{ display: 'flex' }}>
                          <BitaCheckbox
                            checked={constituentsDelete
                              .map(item => item?.isin ?? item?.sedol)
                              .includes(dataValue?.isin ?? dataValue?.sedol)}
                            handleChange={checked => {
                              if (checked) {
                                setConstituentsDelete([
                                  ...constituentsDelete,
                                  dataValue,
                                  // dataValue?.isin ?? dataValue?.sedol,
                                ]);
                              } else {
                                setConstituentsDelete(
                                  constituentsDelete.filter(
                                    item =>
                                      (item?.isin ?? item?.sedol) !==
                                      (dataValue?.isin ?? dataValue?.sedol),
                                  ),
                                );
                              }
                            }}
                          />
                          {value}
                        </div>
                      ) : entryKey === 'weights' ? (
                        value.toFixed(6)
                      ) : (
                        truncateString(value, 13) ?? '-'
                      )}
                    </p>
                    {entryKey === 'type' && value !== 'with_coverage' && (
                      <div className="tooltip error-tooltip">
                        <div>
                          <BiX className="tooltip-icon icon-error" />
                        </div>
                        <span>
                          This instrument is not under the BITA Calculation platform pricing
                          coverage. You can request the coverage using the button below &quot;
                          Request Coverage &quot;. We will receive the request, process it, and
                          communicate with you via e-mail as soon as possible.
                        </span>
                      </div>
                    )}
                    {entryKey === 'type' && value === 'with_coverage' && (
                      <div className="tooltip success-tooltip">
                        <div>
                          <BiCheck className="tooltip-icon icon-info" />
                        </div>
                        <span>The input has been successfully validated</span>
                      </div>
                    )}
                  </div>
                )}
                // eslint-disable-next-line no-shadow
              />
            )}
          </div>
        );
      }

      // Hide this for now
      // if (type === 'Bottom-Up Basket' && data.children_symbols) {
      //   const rows = data.children_symbols.map(symbol => ({
      //     children_symbols: symbol,
      //   }));

      //   return (
      //     <>
      //       <div style={{ border: 'none', marginBottom: '0px' }}>Baskets</div>
      //       <BitaTable
      //         data={rows}
      //         search={false}
      //         height="300px"
      //         showFields={['children_symbols']}
      //         selectAction={() => {}}
      //         CustomRowComponent={({ value }) => (
      //           <div>
      //             <p>{value}</p>
      //           </div>
      //         )}
      //       />
      //     </>
      //   );
      // }

      // Hide this for now
      // if (type === 'Long/Short' && data.global_baskets) {
      //   const rows = Object.values(data.global_baskets).map(symbol => ({
      //     basket_symbol: symbol,
      //   }));

      //   return (
      //     <>
      //       <div style={{ border: 'none', marginBottom: '0px' }}>Baskets</div>

      //       <BitaTable
      //         data={rows}
      //         search={false}
      //         height="300px"
      //         showFields={['basket_symbol']}
      //         selectAction={() => {}}
      //         CustomRowComponent={({ value }) => (
      //           <div>
      //             <p>{value}</p>
      //           </div>
      //         )}
      //       />
      //     </>
      //   );
      // }

      if (type === 'Decrement Index') {
        return <></>;
      }
      return null;
    };

    return <div className={titleBasketTable ? '' : style.contentWidget}>{renderTable()}</div>;
  };

  const BacktestTable = () => {
    const renderTable = () => {
      const columns = Object.keys(dataBacktest?.[0]);
      const rows = dataBacktest.slice(1);

      return (
        <>
          <div style={{ border: 'none', marginBottom: '0px' }}>Backtest Preview</div>
          <BitaTable
            data={rows}
            search={false}
            showFields={columns}
            height="300px"
            showOnly={10}
            pagination
            selectAction={() => {}}
            CustomRowComponent={({ value }) => (
              <div>
                <p style={{ paddingRight: '20px' }}>{value}</p>
              </div>
            )}
          />
        </>
      );
    };
    return <div className={style.contentWidget}>{renderTable()}</div>;
  };

  const fieldsValidations = () => {
    try {
      if (!data) return false;

      return (
        data?.meta_reference_data &&
        validarData(data, validateData) &&
        validateFields('cash_distribution', data?.cash_distribution) &&
        validateFields('application_point', data?.application_point) &&
        validateFields('calculation_type', data?.calculation_type) // This is return type
      );
    } catch (error) {
      console.error('Validation error:', error);
      return false;
    }
  };

  const existRequestCoverage = () => {
    let coverage = false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data?.meta_reference_data?.length; i++) {
      if (data?.meta_reference_data[i]?.type !== 'with_coverage') {
        coverage = true;
        break;
      }
    }
    return coverage;
  };

  const updateRequestCoverage = calculation_frequency => {
    recalculateCoverage(token, {
      constituents: data?.meta_reference_data.map(item => {
        return {
          isin: item.isin,
          exchange: item?.exchange,
        };
      }),
      calculation_setup: calculation_frequency,
    })
      .then(rsp => {
        if (!rsp.data.data.full_coverage) {
          setData(prev => {
            return {
              ...prev,
              meta_reference_data: prev?.meta_reference_data.map(constituent => {
                return {
                  ...constituent,
                  type: rsp.data.data.request_coverage
                    ?.map(item => item.isin)
                    ?.includes(constituent?.isin ?? constituent?.sedol)
                    ? 'request_coverage'
                    : 'with_coverage',
                };
              }),
            };
          });
        }
      })
      .catch(error => {
        console.info(error);
      });
  };

  // Function to validate if either the divisor or starting value is valid
  const isDivisorOrStartingValueValid = () => {
    const { divisor, starting_value } = data || {};
    const { statusDivisor, statusStarting } = enableDivisorOrStartingValue;

    return (divisor > 0 && statusDivisor) || (starting_value > 0 && statusStarting);
  };

  const interestCashValid =
    !data?.cash_constituent ||
    (data?.cash_constituent?.interest_rate_on_cash != null &&
      parseFloat(data?.cash_constituent?.interest_rate_on_cash.toString().replace(/,/g, '')) >= 0 &&
      data?.cash_constituent?.interest_rate_on_cash !== '');

  const applyButtonValidations = () => {
    const { meta_reference_data, currency, divisor, starting_value } = data || {};

    // Check if there is valid basket data or backtest data
    const hasValidBasketDataOrBacktestData = meta_reference_data || dataBacktest.length > 0;

    // Check if there is exchange data available
    const hasExchangeData = meta_reference_data?.[0]?.exchange || false;
    const hasMetadataExists = meta_reference_data?.length > 0;

    // Function to validate if dissemination settings are valid
    const isDisseminationValid = () => {
      // If dissemination is not Bloomberg, it is always valid
      if (data.dissemination !== 'bita_bloomberg') return true;
      const setup = data?.bloomberg_dissemination_setup;

      // Return true if all conditions are met, otherwise false
      return (
        setup?.geographical_region &&
        setup?.timezone_of_trading_hours &&
        setup?.start_time &&
        setup?.end_time &&
        setup?.trading_days &&
        (setup?.global || setup?.country_code)
      );
    };

    // Object containing all the validation checks
    const validations = {
      name: data?.name && data?.name !== '',
      // Checks if there is no launch date error
      interestCashValid,
      validateData: !errorReferenceData,
      basketDataOrBacktestData: hasValidBasketDataOrBacktestData, // Checks if there is valid basket or backtest data
      requestCoverage: !existRequestCoverage(), // Checks if there is no request coverage
      exchangeData: hasExchangeData, // Checks if exchange data is present
      hasMetadataExists, // Check is metadata exists
      bloomberg: isDisseminationValid(),
      fields: fieldsValidations(), // Checks if fields are valid
      currency: currency && validateFields('currency', currency), // Validates currency
      divisorOrStartingValue: isDivisorOrStartingValueValid(), // Checks if divisor or starting value is valid
    };

    // Returns true if any of the validations fail (i.e., are false)
    return Object.values(validations).some(validation => !validation);
  };

  const handleApplyBtn = () => {
    const fullTicker = orgPrefix + data?.symbol;

    consultExistBasket(token, fullTicker)
      .then(rsp => {
        if (rsp.data?.data.length > 0) {
          setErrorSymbol(true);
          inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          inputRef.current.focus();
          toast.error(
            `The ticker ${data?.symbol} is already in use. Please, choose a different one.`,
          );
        } else {
          setShowModalConfirm(true);
          if (data?.divisor > 0 && enableDivisorOrStartingValue.statusDivisor) {
            const { starting_value: _, ...updatedData } = data;
            setData({ ...updatedData });
          } else if (data?.starting_value > 0 && enableDivisorOrStartingValue.statusStarting) {
            const { divisor: _, ...updatedData } = data;
            setData({ ...updatedData });
          }
        }
      })
      .catch(error => {
        toastInterceptor(error);
      });
  };

  const handleConstituentToggle = value => {
    setConstituentsDelete(prev => {
      const exists = constituentsDelete.some(
        item => item.isin === value?.isin || (!value?.isin && item.sedol === value?.sedol),
      );
      if (exists) {
        return prev.filter(
          item =>
            !(
              (value.isin && item.isin === value.isin) ||
              (!value?.isin && value.sedol && item.sedol === value.sedol)
            ),
        );
      }
      return [...prev, value];
    });
  };

  return (
    <div>
      {/* Basket Composition/Portfolio */}
      <div className={styles.compositionPorfolio}>
        <div className={styles.compositionPorfolioTitle}>Basket Composition/Portfolio</div>
        <div className={style.contentDataUpload}>
          <div className={style.dataUpload}>
            <div>
              <div className={style.subtitle}>RETURN TYPE</div>
              <div style={{ marginTop: '10px' }}>
                <BitaSelect
                  warningIcon
                  selected={type}
                  value={{ value: type, id: type, name: type }}
                  label="level"
                  data={DATA_LIST_DROPDOWN}
                  handleSelection={value => setType(value.value)}
                  style={FONT_SIZE}
                />
              </div>
            </div>

            {type !== 'Decrement Index' && (
              <>
                <div className={style.contentTemplate}>
                  <div className={style.subtitle}>TEMPLATES</div>
                  <div
                    style={{ display: 'flex', gap: '20px', marginTop: '10px', flexWrap: 'wrap' }}
                  >
                    {Object.values(basketButton[type] ?? {}).map(button => (
                      <BitaButton
                        primaryWhite
                        width="160px"
                        height="35px"
                        onClick={() => {
                          FileSaver.saveAs(
                            `${process.env.PUBLIC_URL}/files/${button?.url}.csv`,
                            `${button?.url}.csv`,
                          );
                        }}
                      >
                        {button?.label}
                      </BitaButton>
                    ))}
                  </div>
                </div>

                <div>
                  <div className={style.subtitle}>Portfolio Uploader</div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '10px',
                      gap: '20px',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <input
                      type="file"
                      onChange={event => {
                        cleanStates(); // without clearing the useRefs
                        setFiles(event.target.files[0]);
                      }}
                      ref={inputBasketRef}
                      name="file"
                      id="file"
                      className={style.inputfile}
                    />
                    <label htmlFor="file">Select File</label>
                    <BitaButton
                      disabled={!files || buttonStateLoading.basket}
                      width="160px"
                      onClick={() => {
                        setButtonStateLoading({ ...buttonStateLoading, basket: true });
                        const formData = new FormData();
                        formData.append('template', files);
                        if (type === 'Long only Basket') {
                          formData.append('type', 'basket');
                          // formData.append('org_prefix', orgPrefix); // Remove for now
                        } else {
                          formData.append(
                            'type',
                            type.includes('Bottom') ? 'global' : type.replace('/', '_'),
                          );
                        }
                        setData({});
                        setDataBacktest([]);
                        setFilesBacktest('');
                        if (files) {
                          uploadFileSettings(token, formData)
                            .then(rsp => {
                              toast('Your file has been processed successfully');
                              // const constituents = Object.keys(rsp.data.data?.meta_reference_data.awf);
                              const constituents = rsp.data.data?.meta_reference_data;
                              const constituentsWithError = constituents.filter(
                                (constituyente, index) => index % 3 === 0,
                              );
                              setConstituentsError(constituentsWithError);
                              const timeData = LIST_TIMEZONE_LIVE_BASKET.find(
                                item => item.calculationZone === rsp.data.data?.calculation_zone,
                              );
                              if (
                                existRequestCoverageWithData(rsp.data.data?.meta_reference_data)
                              ) {
                                toast.warning('Some instruments are not covered');
                              }
                              const dataFormated = {
                                ...rsp.data.data,
                                calculation_zone: validateFields(
                                  'calculation_zone',
                                  rsp.data.data?.calculation_zone,
                                )
                                  ? rsp.data.data?.calculation_zone
                                  : '',
                                region: timeData?.region,
                                timezone: timeData?.timezone,
                                meta_reference_data: orderByType(
                                  rsp.data.data?.meta_reference_data,
                                ),
                                calculation_close_time: timeData?.calculationEnd,
                                calculation_open_time: timeData?.calculationStart,
                                start_week_day: timeData?.startWeekDay,
                                end_week_day: timeData?.endWeekDay,
                                dissemination: 'bita_only',
                                divisor: rsp.data.data?.divisor,
                                calculationEodTime: timeData?.calculationEodTime,
                                calculation_eod_time: timeData?.calculationEodTime,
                              };
                              setData(dataFormated);
                              setPagination(INITIAL_PAGINATION);
                              setFilterTable('');
                              const launchDateString = rsp.data.data?.launch_date;
                              if (launchDateString) {
                                if (isValidDateFormat(launchDateString)) {
                                  if (isValidDateString(launchDateString)) {
                                    // it is a valid date
                                    const date = new Date(launchDateString);
                                    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                                    setLaunchDate(date);
                                    setErrorCalendar(false);
                                  } else {
                                    // it is not a valid date, so the user should add it manually
                                    setLaunchDate(null);
                                    setErrorCalendar(true);
                                  }
                                } else {
                                  // It is not in the correct format, so the user should add it manually
                                  setLaunchDate(null);
                                  setErrorCalendar(true);
                                }
                              } else {
                                // The launch date is not included in the API response, so add today's date
                                setLaunchDate(new Date());
                                setErrorCalendar(false);
                              }
                              if (dataFormated?.deployment_setup === 'IMMEDIATELY') {
                                setLaunchDate(new Date());
                                setErrorCalendar(false);
                              }
                              setDataInitial(dataFormated);

                              inputBasketRef.current.value = '';

                              const sedolNotFoundArr = rsp.data.data?.sedols_not_found || null;
                              if (sedolNotFoundArr && sedolNotFoundArr.length > 0) {
                                setSedolsList(sedolNotFoundArr);
                                setShowSedolNotFoundModal(true);
                              }

                              // set Divisor and start value first condition
                              if (rsp.data.data?.starting_value && !rsp.data.data?.divisor) {
                                SetEnableDivisorOrStartingValue({
                                  statusDivisor: false,
                                  statusStarting: true,
                                });
                              }
                            })
                            .catch(error => {
                              toastInterceptor(error);
                            })
                            .finally(() => {
                              setButtonStateLoading({ ...buttonStateLoading, basket: false });
                            });
                        } else {
                          toast.error(
                            'Please upload a portfolio template with a different file name.',
                          );
                          setButtonStateLoading({ ...buttonStateLoading, basket: false });
                        }
                      }}
                      primary
                      height="35px"
                    >
                      {buttonStateLoading.basket ? <BitaSpinner pointers /> : 'Upload File'}
                    </BitaButton>
                  </div>
                  <p className={style.nameFile}>{files && `(${files?.name})`}</p>
                  {data?.shares_determination_point && (
                    <div className={style.contentSharesDetermination}>
                      <div className={style.subtitleWhite}>
                        SHARES - PRICING DETERMINATION POINT
                      </div>
                      <div className={style.sharesDeterminationDropdown}>
                        <BitaSelect
                          selected={data?.shares_determination_point}
                          label={SHARES_DETERMINATION_POINT[data?.shares_determination_point]}
                          data={[]}
                          handleSelection={value => {}}
                          style={FONT_SIZE}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div>
            <div
              className="contentTableBasketPreview"
              style={{ marginTop: '0px', paddingTop: '0px' }}
            >
              <div className="headerTable">
                <div style={{ border: 'none', marginBottom: '0px' }}>Basket Data Preview</div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h1>General file information section</h1>
                </div>
              </div>
              <div style={{ height: data.meta_reference_data ? '600px' : '400px' }}>
                {data && data.meta_reference_data && (
                  <div>
                    <Table
                      basket={files?.name}
                      rows={data.meta_reference_data}
                      listMic={listMIC}
                      calculationSetup={data.calculation_setup}
                      hiddenColumn={data?.identifier_type === 'isin' ? ['sedol'] : []}
                      setMetadata={setData}
                      constituentsDelete={constituentsDelete}
                      setConstituentsDelete={value => handleConstituentToggle(value)}
                      allDelete={value => {
                        setConstituentsDelete(value);
                      }}
                      setErrorReferenceData={setErrorReferenceData}
                    />
                  </div>
                )}
              </div>
              {data && (
                <BitaButton
                  disabled={
                    data?.meta_reference_data?.filter(item => item.type !== 'with_coverage')
                      .length === 0 || !(data?.meta_reference_data?.length > 0)
                  }
                  primary
                  width="160px"
                  style={{ marginRight: 'auto', marginTop: '20px' }}
                  height="35px"
                  onClick={() => setShowModalRequestCoverage(true)}
                >
                  Request Coverage
                </BitaButton>
              )}

              {data && (
                <BitaButton
                  disabled={constituentsDelete.length === 0}
                  primary
                  width="160px"
                  style={{ marginLeft: '20px', marginTop: '20px' }}
                  height="35px"
                  onClick={() => {
                    setShowModalDelete(true);
                  }}
                >
                  Delete Constituent
                </BitaButton>
              )}
              <BitaButton
                primary
                width="160px"
                style={{ marginLeft: '20px', marginTop: '20px' }}
                height="35px"
                onClick={() => {
                  setShowModalAdd(true);
                }}
              >
                Add Instrument
              </BitaButton>
            </div>
          </div>
        </div>
      </div>

      {/* Backtest Section */}
      {type !== 'Decrement Index' && (
        <>
          <div className={styles.compositionPorfolio}>
            <div className={styles.compositionPorfolioTitle}>Backtest</div>
            <div className={style.contentDataUpload}>
              <div className={style.dataUpload}>
                <div>
                  <div>
                    <div className={style.subtitle}>TEMPLATES</div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '20px',
                        marginTop: '10px',
                        marginBottom: '20px',
                        flexWrap: 'wrap',
                      }}
                    >
                      <BitaButton
                        primaryWhite
                        width="160px"
                        height="35px"
                        onClick={() => {
                          FileSaver.saveAs(
                            `${process.env.PUBLIC_URL}/files/backtest_template.csv`,
                            `backtest_template.csv`,
                          );
                        }}
                      >
                        Backtest Template
                      </BitaButton>
                    </div>
                  </div>
                  <div className={style.subtitle}>Backtest Uploader</div>
                  <div
                    className={style.containerButtonsUpload}
                    style={{
                      pointerEvents: files?.name ? 'auto' : 'none',
                      opacity: files?.name ? 1 : 0.5,
                    }}
                  >
                    <div>
                      <input
                        type="file"
                        accept=".csv"
                        onChange={event => {
                          const file = event.target.files[0];
                          setFilesBacktest(file);
                          setDataBacktest({});
                        }}
                        style={{ display: 'none' }}
                        id="file-upload"
                      />
                      <label htmlFor="file-upload" className={styles.buttonUpload}>
                        Select File
                      </label>
                      <div style={styles.acceptedFile} />
                    </div>
                    <BitaButton
                      disabled={!filesBacktest?.name || buttonStateLoading.backtest}
                      width="160px"
                      onClick={() => {
                        setButtonStateLoading({ ...buttonStateLoading, backtest: true });
                        Papa.parse(filesBacktest, {
                          complete: results => {
                            if (
                              results.data.length > 1 &&
                              results.data[0][0].toUpperCase() === 'DATE' &&
                              results.data[0][1].toUpperCase() === 'VALUE'
                            ) {
                              setDataBacktest(results.data);
                            } else {
                              toast.error(
                                'Invalid file type structure. Please upload a file that matches the required format',
                              );
                            }
                            setButtonStateLoading({ ...buttonStateLoading, backtest: false });
                          },
                          header: false,
                        });
                      }}
                      primary
                      height="35px"
                    >
                      {buttonStateLoading.backtest ? <BitaSpinner pointers /> : 'Upload File'}
                    </BitaButton>
                    {/* <div className={style.nameFile}>
                      {filesBacktest && `(${filesBacktest.name})`}
                    </div> */}
                  </div>
                  <p className={style.nameFile}>{filesBacktest && `(${filesBacktest?.name})`}</p>
                </div>
              </div>
              <div>{dataBacktest.length > 0 && <BacktestTable />}</div>
            </div>
          </div>

          {data && Object.keys(data ?? {}).length > 0 && (
            <div className={styles.compositionPorfolio}>
              <div className={styles.compositionPorfolioTitle}>Basket Parameters</div>

              <div className={style.contentWidget}>
                <div>Start Parameters</div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <BitaInput
                        value={data?.name || ''}
                        type="text"
                        inputStyle={
                          !data?.name || data?.name === '' ? style.nameInputNeed : style.nameInput
                        }
                        title="Name"
                        externalTitle="Name"
                        placeholder="Name"
                        justify="flex-start"
                        handleChange={value => {
                          handleChange('name', value);
                        }}
                      />
                      <BitaInput
                        value={data?.description || ''}
                        type="text"
                        inputStyle={style.nameInput}
                        title="Description"
                        externalTitle="Description"
                        placeholder="Description"
                        justify="flex-start"
                        handleChange={value => {
                          handleChange('description', value);
                        }}
                      />
                    </div>
                    <div
                      className={style.contentInput}
                      style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
                    >
                      <div>
                        <BitaInput
                          value={orgPrefix}
                          type="text"
                          name="symbol"
                          inputStyle={style.basketInput}
                          title="Org. Prefix"
                          maxlength={3}
                          handleChange={val => {
                            const inputValue = val;
                            const isValid = /^[A-Za-z0-9]+$/.test(inputValue);
                            if (isValid || val === '') setOrgPrefix(val.toUpperCase());
                          }}
                          externalTitle="Org. Prefix"
                        />
                      </div>
                      <div ref={inputRef}>
                        <BitaInput
                          value={data?.symbol}
                          type="text"
                          ref={inputRef}
                          inputStyle={
                            !data?.symbol ||
                            orgPrefix?.length + data?.symbol?.length > 8 ||
                            errorSymbol
                              ? style.needValidation
                              : style.basketInput
                          }
                          inputSymbol
                          title="Ticker"
                          maxlength={8 - (orgPrefix?.length ?? DUMMIE_PREFIX?.length)}
                          externalTitle="Ticker"
                          placeholder="Ticker"
                          tooltip={
                            8 - (orgPrefix?.length ?? DUMMIE_PREFIX?.length) < 0
                              ? `Character limit exceeded: Maximum of ${8 -
                                  (orgPrefix?.length ?? DUMMIE_PREFIX?.length)} characters allowed.`
                              : `Character limit: Maximum of ${8 -
                                  (orgPrefix?.length ?? DUMMIE_PREFIX?.length)} characters allowed.`
                          }
                          handleChange={val => {
                            const inputValue = val;
                            const isValid = /^[A-Za-z0-9]+$/.test(inputValue);
                            if (isValid || val === '') handleChange('symbol', val.toUpperCase());
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div
                          className={style.contentInput}
                          style={{
                            display: 'flex',
                            gap: '12px',
                            justifyContent: 'flex-start',
                            alignItems: 'baseline',
                            flexDirection: 'column',
                            marginRight: '40px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '18px',
                              width: 'auto',
                            }}
                          >
                            <div>Starting Value</div>
                            <div>
                              <Switcher.Switch
                                className={styles.customRadio}
                                onChange={() => {
                                  SetEnableDivisorOrStartingValue({
                                    statusStarting: !enableDivisorOrStartingValue.statusStarting,
                                    statusDivisor: !enableDivisorOrStartingValue.statusDivisor,
                                  });
                                  if (
                                    data?.divisor_determination_setup === 'IMMEDIATELY' &&
                                    data.launch_point === 'MARKET_CLOSE'
                                  ) {
                                    setData({
                                      ...data,
                                      launch_point: '',
                                    });
                                  }
                                }}
                                checked={enableDivisorOrStartingValue.statusStarting}
                              />
                            </div>
                          </div>
                          {enableDivisorOrStartingValue.statusStarting && (
                            <BitaInput
                              value={data?.starting_value}
                              type="number"
                              inputStyle={
                                !data?.starting_value ? style.needValidation : style.basketInput
                              }
                              title="Start Value"
                              placeholder="Enter Value"
                              lower_limit={0}
                              handleChange={val => {
                                handleChange('starting_value', Number(val.replace(/,/g, '')));
                              }}
                            />
                          )}
                        </div>
                        {enableDivisorOrStartingValue.statusStarting && (
                          <BitaSelect
                            warningIcon
                            selected={data?.divisor_determination_setup}
                            value={
                              validateFields(
                                'divisor_determination_setup',
                                data?.divisor_determination_setup,
                              )
                                ? DATA_DIVISOR_DETERMINATION_SETUP.find(
                                    item => item.id === data?.divisor_determination_setup,
                                  )
                                : ''
                            }
                            label="Divisor Determination Point"
                            externalPlaceholder="Divisor Determination Point"
                            data={DATA_DIVISOR_DETERMINATION_SETUP}
                            handleSelection={value => {
                              if (value?.value === 'IMMEDIATELY') {
                                setData({
                                  ...data,
                                  launch_point: '',
                                  divisor_determination_setup: value?.id,
                                });
                              } else {
                                handleChange('divisor_determination_setup', value?.id);
                              }
                            }}
                            selectClassname={
                              !validateFields(
                                'divisor_determination_setup',
                                data?.divisor_determination_setup,
                              ) && style.needValidationSelect
                            }
                          />
                        )}
                      </div>
                      <div
                        className={style.contentInput}
                        style={{
                          display: 'flex',
                          gap: '12px',
                          justifyContent: 'flex-start',
                          alignItems: 'baseline',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '18px',
                          }}
                        >
                          <div>Divisor</div>
                          <div>
                            <Switcher.Switch
                              className={styles.customRadio}
                              onChange={() =>
                                SetEnableDivisorOrStartingValue({
                                  statusStarting: !enableDivisorOrStartingValue.statusStarting,
                                  statusDivisor: !enableDivisorOrStartingValue.statusDivisor,
                                })
                              }
                              checked={enableDivisorOrStartingValue.statusDivisor}
                            />
                          </div>
                        </div>

                        {enableDivisorOrStartingValue.statusDivisor && (
                          <BitaInput
                            value={data?.divisor}
                            type="number"
                            inputStyle={!data?.divisor ? style.needValidation : style.basketInput}
                            title="Divisor"
                            placeholder="Enter Value"
                            lower_limit={0}
                            handleChange={val => {
                              handleChange('divisor', Number(val.replace(/,/g, '')));
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <div
                      className={style.contentInput}
                      style={{
                        display: 'flex',
                        gap: '12px',
                        justifyContent: 'flex-start',
                        alignItems: 'baseline',
                        flexDirection: 'column',
                      }}
                    >
                      <BitaSelect
                        warningIcon
                        selected={data?.calculation_setup ?? ''}
                        value={
                          validateFields('calculation_setup', data?.calculation_setup)
                            ? {
                                value: data?.calculation_setup.replace('_', ' '),
                                id: data?.calculation_setup.replace('_', ' '),
                                name: data?.calculation_setup.replace('_', ' '),
                              }
                            : ''
                        }
                        externalPlaceholder="Calculation Frequency"
                        label="Calculation frequency"
                        data={[
                          { value: 'EOD Only', id: 'EOD' },
                          { value: 'EOD and Intra-day', id: 'EOD_INTRADAY' },
                        ]}
                        handleSelection={value => {
                          handleChange('calculation_setup', value?.id);
                          updateRequestCoverage(value?.id);
                        }}
                        selectClassname={
                          !validateFields('calculation_setup', data?.calculation_setup) &&
                          style.needValidationSelect
                        }
                      />
                    </div>

                    <div
                      className={style.contentInput}
                      style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
                    >
                      {/* <div style={{ pointerEvents: 'none' }}>
                <BitaInput
                  value={data?.calculation_open_time}
                  type="time"
                  inputStyle={style.basketInput}
                  title="Calculation Start"
                  externalTitle="Calculation Start"
                  placeholder="Ticker"
                  disabled
                  handleChange={val => {
                    handleChange('calculation_open_time', val);
                  }}
                />
                </div>
                <div style={{ pointerEvents: 'none' }}>
                <BitaInput
                  value={data?.calculation_close_time}
                  type="time"
                  inputStyle={style.basketInput}
                  title="Calculation End"
                  externalTitle="Calculation End"
                  placeholder="Enter Value"
                  disabled
                  handleChange={val => {
                    handleChange('calculation_close_time', val);
                  }}
                />
                </div> */}

                      <BitaSelect
                        warningIcon
                        selected={data?.calculation_zone}
                        value={{
                          value: timezone.find(
                            item => item?.calculationZone === data?.calculation_zone,
                          )?.name,
                          id: data?.calculation_zone,
                          name: data?.calculation_zone,
                        }}
                        label="Calculation Zone"
                        externalPlaceholder="Calculation Zone"
                        data={timezone.filter(
                          item =>
                            item.value !== 'Eastern Asia Pacific' ||
                            hasValidUBSAPACOnly(user?.userdata.id_company) ||
                            hasValidBitaDomain(user?.userdata.email),
                        )}
                        handleSelection={value => {
                          handleChange('calculationZone', value?.value);
                        }}
                        selectClassname={
                          !validateFields('calculation_zone', data?.calculation_zone) &&
                          style.needValidationSelect
                        }
                      />
                      {data?.calculation_setup === 'EOD_INTRADAY' && (
                        <BitaSelect
                          warningIcon
                          selected={data?.launch_point}
                          value={
                            validateFields('launch_point', data?.launch_point)
                              ? {
                                  value: DATA_CALCULATION_TYPE[data?.launch_point],
                                  id: data?.launch_point,
                                  name: data?.launch_point,
                                }
                              : ''
                          }
                          label="Launch Point"
                          externalPlaceholder="Launch Point"
                          data={DATA_CALCULATION.map(item => ({
                            ...item,
                            disabled:
                              item.value === 'MARKET_CLOSE' &&
                              enableDivisorOrStartingValue.statusStarting &&
                              data?.divisor_determination_setup === 'IMMEDIATELY',
                          }))}
                          handleSelection={value => {
                            handleChange('launch_point', value?.value);
                            if (value?.value === 'IMMEDIATELY') {
                              setLaunchDate(new Date());
                              setErrorCalendar(false);
                            }
                          }}
                          selectClassname={
                            !validateFields('launch_point', data?.launch_point) &&
                            style.needValidationSelect
                          }
                        />
                      )}
                      {data?.launch_point !== 'MARKET_OPEN' && (
                        <div
                          className={
                            errorCalendar || !launchDate
                              ? `needValidationSelect submitBasketCalendar `
                              : 'submitBasketCalendar'
                          }
                        >
                          <BitaCalendarDate
                            toUpperCaseTitle={false}
                            title="Launch Date"
                            select={value => {
                              if (value !== launchDate) {
                                setErrorCalendar(
                                  value === 'Invalid date' ||
                                    value < new Date(new Date().setDate(new Date().getDate() - 1)),
                                );
                              }

                              setLaunchDate(value);
                            }}
                            maxDate={new Date('3000-12-12')}
                            minDate={new Date()}
                            date={launchDate}
                            tooltip="Select today's date or a future date"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={style.contentNoMetadata} style={{ width: '28%' }}>
                    <div className={style.tableNoMetadata}>
                      <div style={{ borderBottom: '1px solid #c7c7c7' }}>
                        Calculation Zone Details
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div>Timezone: </div> <div>{data?.timezone}</div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div>Calculation Start: </div> <div>{data?.calculation_open_time}</div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div>Calculation End: </div> <div>{data?.calculation_close_time}</div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div>Start Week Day: </div>{' '}
                        <div>{BUSINESS_DAYS[data?.calculation_start_week_day] || 'Monday'}</div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div>End Week Day: </div>{' '}
                        <div>{BUSINESS_DAYS[data?.calculation_end_week_day] || 'Friday'}</div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div>Calculation EOD Time: </div> <div>{data?.calculationEodTime}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {(divisorPreviousEODLaunchImmediate ||
                divisorImmediatelyLaunchImmediately ||
                enableDivisorOrStartingValue.statusDivisor) && (
                <div className={style.messageDivisorStartingValue}>
                  <BiSolidError className="icon-error" />
                  {divisorPreviousEODLaunchImmediate &&
                    MESSAGES_ALERT.divisorPreviousEODLaunchImmediate}
                  {divisorImmediatelyLaunchImmediately &&
                    MESSAGES_ALERT.divisorImmediatelyLaunchImmediately}
                  {enableDivisorOrStartingValue.statusDivisor && MESSAGES_ALERT.divisor}
                </div>
              )}

              <div className={style.contentWidget}>
                <div>Return type</div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '25px',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '25px',
                        justifyContent: 'flex-start',
                        alignItems: 'end',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: '25px',
                          justifyContent: 'flex-start',
                          alignItems: 'end',
                        }}
                      >
                        <BitaSelect
                          warningIcon
                          selected={data?.calculation_type}
                          value={
                            validateFields('calculation_type', data?.calculation_type)
                              ? {
                                  value: data?.calculation_type.replace('_', ' '),
                                  id: data?.calculation_type,
                                }
                              : ''
                          }
                          label="Return calculation"
                          data={[
                            { value: 'NET RETURN', id: 'NET_RETURN' },
                            { value: 'TOTAL RETURN', id: 'TOTAL_RETURN' },
                            { value: 'PRICE RETURN', id: 'PRICE_RETURN' },
                          ]}
                          handleSelection={value => handleChange('calculation_type', value?.id)}
                          selectClassname={
                            !validateFields('calculation_type', data?.calculation_type) &&
                            style.needValidationSelect
                          }
                        />
                        {data?.calculation_type === 'NET_RETURN' && (
                          <div style={{ display: 'flex', gap: '10px', alignItems: 'end' }}>
                            <BitaSelect
                              width="330px"
                              warningIcon
                              selected={data?.withholding_tax_id ?? 'BITA Withholding Taxes'}
                              data={templateData?.map(template => {
                                return { value: template?.withholding_tax_name, id: template?.id };
                              })}
                              value={
                                validateFields('withholding_tax_id', data?.withholding_tax_id)
                                  ? {
                                      value: templateData?.find(
                                        item => item.id === data?.withholding_tax_id,
                                      )?.withholding_tax_name,
                                      id: data?.withholding_tax_id,
                                    }
                                  : {
                                      value: 'BITA Withholding Taxes',
                                      id: 'BITA Withholding Taxes',
                                    }
                              }
                              externalPlaceholder="Withholding Taxes Template"
                              label="Withholding Taxes Template"
                              handleSelection={value =>
                                handleChange('withholding_tax_id', value?.id)
                              }
                            />
                            <BitaButton
                              width="auto"
                              height="35px"
                              className={style.withholdingTaxButton}
                              onClick={() => {
                                setOpenWithholdingModal(!openWithholingModal);
                              }}
                            >
                              Manage Withholding Taxes
                            </BitaButton>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '18px',
                        }}
                      >
                        <div>Annualized Daily Decrement</div>
                        <div>
                          <Switcher.Switch
                            className={styles.customRadio}
                            onChange={() => {
                              setIsActiveAnnualizedYearlyDecrement(
                                !isActiveAnnualizedYearlyDecrement,
                              );
                              handleChange('percentage_value', null);
                            }}
                            checked={isActiveAnnualizedYearlyDecrement}
                          />
                        </div>
                      </div>

                      <div>
                        {isActiveAnnualizedYearlyDecrement && (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <BitaInput
                              value={data?.percentage_value ?? 0}
                              type="number"
                              upper_limit={100}
                              lower_limit={0}
                              inputStyle={style.basketInput}
                              title="Percentage"
                              externalTitle="Percentage"
                              placeholder="Enter Percentage"
                              tooltip="Please indicate a percentage between 0 and 100"
                              handleChange={val => {
                                handleChange('percentage_value', val);
                              }}
                            />
                            <div style={{ marginTop: '15px', marginLeft: '30px' }}>
                              Day-Count Conversion: 360 Days
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.contentWidget}>
                <div>Corporate Actions Methodology</div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '12px',
                      justifyContent: 'flex-start',
                      pointerEvents: 'none',
                    }}
                  >
                    <div>BITA CORPORATE ACTIONS METHODOLOGY</div>
                    <Switcher.Switch
                      className={styles.customRadio}
                      onChange={() => handleChange('corporate_actions_methodology', 'BITA')}
                      checked={data?.corporate_actions_methodology === 'BITA'}
                    />
                  </div>
                  {/* ALL remember to remove the hardcode from corporate_actions_methodology  */}
                  <div
                    style={{
                      display: 'flex',
                      gap: '12px',
                      justifyContent: 'flex-start',
                      opacity: 0.5,
                      pointerEvents: 'none',
                    }}
                  >
                    <div>CUSTOM METHODOLOGY</div>
                    <Switcher.Switch
                      className={styles.customRadio}
                      onChange={() => handleChange('corporate_actions_methodology', 'CUSTOM')}
                      checked={data?.corporate_actions_methodology !== 'BITA'}
                    />
                  </div>
                  {data?.corporate_actions_methodology &&
                    data?.corporate_actions_methodology !== 'BITA' && (
                      <div style={{ marginTop: '10px' }}>
                        <BitaSelect
                          warningIcon
                          selected={data?.calculation_type}
                          value=""
                          label="Methodology"
                          data={[]}
                          handleSelection={value => handleChange('', value?.id)}
                          style={FONT_SIZE}
                        />
                      </div>
                    )}
                </div>
              </div>
              {data.cash_constituent && (
                <div className={style.contentWidget}>
                  <div>Cash Constituents Configuration</div>
                  <div
                    className={style.contentInputCash}
                    style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
                  >
                    <BitaInput
                      value={data?.cash_constituent?.interest_rate_on_cash}
                      type="number"
                      style={{ with: '150px' }}
                      inputStyle={
                        !interestCashValid ? style.needValidationCash : style.basketInputCash
                      }
                      title="Interest Rate on Cash"
                      externalTitle="Interest Rate on Cash"
                      placeholder="Enter Value"
                      lower_limit={0}
                      handleChange={val => {
                        handleChange('cash_constituent', {
                          ...data?.cash_constituent,
                          interest_rate_on_cash: val.replace(/,/g, ''),
                        });
                      }}
                    />
                  </div>
                </div>
              )}

              {data?.calculation_type !== 'PRICE_RETURN' && (
                <div className={style.contentWidget}>
                  <div>Cash Distribution</div>
                  <div
                    className={style.contentInput}
                    style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
                  >
                    <BitaSelect
                      warningIcon
                      width="330px"
                      selected={data?.cash_distribution}
                      value={
                        validateFields('cash_distribution', data?.cash_distribution)
                          ? DATA_CASH_DISTRIBUTION.find(item => item.id === data?.cash_distribution)
                          : ''
                      }
                      label="Cash Distribution"
                      externalPlaceholder="Cash Distribution"
                      data={DATA_CASH_DISTRIBUTION}
                      handleSelection={value => handleChange('cash_distribution', value?.id)}
                      selectClassname={
                        !validateFields('cash_distribution', data?.cash_distribution) &&
                        style.needValidationSelect
                      }
                    />

                    <BitaSelect
                      warningIcon
                      width="330px"
                      selected={data?.application_point}
                      label="Application Point"
                      externalPlaceholder="Application Point"
                      value={
                        validateFields('application_point', data?.application_point)
                          ? DATA_APPLICATION_POINT.find(item => item.id === data?.application_point)
                          : ''
                      }
                      data={DATA_APPLICATION_POINT}
                      handleSelection={value => handleChange('application_point', value?.id)}
                      selectClassname={
                        !validateFields('application_point', data?.application_point) &&
                        style.needValidationSelect
                      }
                    />
                  </div>
                </div>
              )}

              <div className={style.contentWidget}>
                <div>Currency</div>
                <div
                  className={style.contentInput}
                  style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
                >
                  <BitaSelect
                    warningIcon
                    selected={data?.currency}
                    value={
                      validateFields('currency', data?.currency)
                        ? {
                            value: data?.currency,
                            id: data?.currency,
                          }
                        : ''
                    }
                    label="Currency"
                    data={CURRENCIES_ARRAY.map(item => {
                      return { value: item, id: item };
                    })}
                    handleSelection={value => handleChange('currency', value?.id)}
                    selectClassname={
                      !data?.currency || !validateFields('currency', data?.currency)
                        ? style.needValidationSelect
                        : ''
                    }
                  />
                </div>
              </div>

              <div className={style.contentWidget}>
                <div>Dissemination Options</div>
                <div
                  className={style.contentInput}
                  style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
                >
                  <BitaSelect
                    warningIcon
                    selected={data?.dissemination}
                    value={
                      validateFields('dissemination', data?.dissemination)
                        ? DATA_DISSEMINATION.find(item => item.id === data?.dissemination)
                        : {
                            value: 'BITA Only',
                            id: 'bita_only',
                          }
                    }
                    label="Dissemination"
                    data={DATA_DISSEMINATION}
                    handleSelection={value => handleChange('dissemination', value?.id)}
                    style={FONT_SIZE}
                  />
                </div>
                {data.dissemination === 'bita_bloomberg' && (
                  <div className={style.contentBloomberg}>
                    <div className={style.contentBloombergRow}>
                      <div>GLOBAL</div>
                      <Switcher.Switch
                        className={styles.customRadio}
                        onChange={value =>
                          handleChange('bloomberg_dissemination_setup', {
                            ...data.bloomberg_dissemination_setup,
                            global: value,
                          })
                        }
                        checked={data?.bloomberg_dissemination_setup?.global}
                      />
                    </div>
                    <div className={style.contentBloomberRow}>
                      {!data?.bloomberg_dissemination_setup?.global && (
                        <BitaSelect
                          selected={data?.bloomberg_dissemination_setup?.country_code}
                          value={
                            data?.bloomberg_dissemination_setup?.country_code
                              ? {
                                  value: data?.bloomberg_dissemination_setup?.country_code,
                                  id: data?.bloomberg_dissemination_setup?.country_code,
                                }
                              : ''
                          }
                          label="Select"
                          externalPlaceholder="Country Code"
                          data={DATA_COUNTRY_CODE.map(item => {
                            return { value: item, id: item };
                          })}
                          handleSelection={value =>
                            handleChange('bloomberg_dissemination_setup', {
                              ...data.bloomberg_dissemination_setup,
                              country_code: value?.id,
                            })
                          }
                          style={FONT_SIZE}
                          selectClassname={
                            !data?.bloomberg_dissemination_setup?.country_code
                              ? style.needValidationSelect
                              : ''
                          }
                        />
                      )}
                      <BitaSelect
                        selected={data?.bloomberg_dissemination_setup?.geographical_region}
                        value={
                          data?.bloomberg_dissemination_setup?.geographical_region
                            ? {
                                value:
                                  GEOGRAPHICAL_REGION_NAMES[
                                    data?.bloomberg_dissemination_setup?.geographical_region
                                  ],
                                id: data?.bloomberg_dissemination_setup?.geographical_region,
                              }
                            : ''
                        }
                        label="Select"
                        placeholder="Geographical region"
                        externalPlaceholder="Geographical region"
                        data={GEOGRAPHICAL_REGION.map(item => {
                          return { value: GEOGRAPHICAL_REGION_NAMES[item], id: item };
                        })}
                        handleSelection={value =>
                          handleChange('bloomberg_dissemination_setup', {
                            ...data.bloomberg_dissemination_setup,
                            geographical_region: value?.id,
                          })
                        }
                        style={FONT_SIZE}
                        selectClassname={
                          !data?.bloomberg_dissemination_setup?.geographical_region
                            ? style.needValidationSelect
                            : ''
                        }
                      />
                    </div>
                    <div className={style.contentBloomberRow}>
                      <BitaSelect
                        selected={data?.bloomberg_dissemination_setup?.timezone_of_trading_hours}
                        value={
                          data?.bloomberg_dissemination_setup?.timezone_of_trading_hours
                            ? {
                                value:
                                  data?.bloomberg_dissemination_setup?.timezone_of_trading_hours,
                                id: data?.bloomberg_dissemination_setup?.timezone_of_trading_hours,
                              }
                            : ''
                        }
                        label="Select"
                        placeholder="Timezone Of Trading Hours"
                        externalPlaceholder="Timezone Of Trading Hours"
                        data={TIMEZONE_OF_TRADING_HOURS.map(item => {
                          return { value: item, id: item };
                        })}
                        handleSelection={value =>
                          handleChange('bloomberg_dissemination_setup', {
                            ...data.bloomberg_dissemination_setup,
                            timezone_of_trading_hours: value?.id,
                          })
                        }
                        selectClassname={
                          !data?.bloomberg_dissemination_setup?.timezone_of_trading_hours
                            ? style.needValidationSelect
                            : ''
                        }
                        style={FONT_SIZE}
                      />

                      <BitaInput
                        value={data?.bloomberg_dissemination_setup?.start_time}
                        type="time"
                        typeTime="time"
                        inputStyle={style.basketInput}
                        title="Start Time"
                        externalTitle="Start Time"
                        placeholder="Ticker"
                        disabled
                        handleChange={val => {
                          handleChange('bloomberg_dissemination_setup', {
                            ...data.bloomberg_dissemination_setup,
                            start_time: val,
                          });
                        }}
                      />
                      <BitaInput
                        value={data?.bloomberg_dissemination_setup?.end_time}
                        type="time"
                        typeTime="time"
                        inputStyle={style.basketInput}
                        title="End Time"
                        externalTitle="End Time"
                        placeholder="Ticker"
                        disabled
                        handleChange={val => {
                          handleChange('bloomberg_dissemination_setup', {
                            ...data.bloomberg_dissemination_setup,
                            end_time: val,
                          });
                        }}
                      />
                    </div>

                    <BitaSelect
                      selected={data?.bloomberg_dissemination_setup?.trading_days}
                      value={
                        data?.bloomberg_dissemination_setup?.trading_days
                          ? {
                              value: data?.bloomberg_dissemination_setup?.trading_days,
                              id: data?.bloomberg_dissemination_setup?.trading_days,
                            }
                          : ''
                      }
                      label="Select"
                      placeholder="Trading Days"
                      externalPlaceholder="Trading Days"
                      data={TRADING_DAYS.map(item => {
                        return { value: item, id: item };
                      })}
                      handleSelection={value =>
                        handleChange('bloomberg_dissemination_setup', {
                          ...data.bloomberg_dissemination_setup,
                          trading_days: value?.id,
                        })
                      }
                      style={FONT_SIZE}
                      selectClassname={
                        !data?.bloomberg_dissemination_setup?.trading_days
                          ? style.needValidationSelect
                          : ''
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}

      {/* Apply Button */}
      <div
        className="contentButtonApply"
        style={{
          width: '100%',
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <BitaButton
          onClick={handleApplyBtn}
          // disabled={errorCalendar || !applyButtonValidations}
          disabled={applyButtonValidations()}
          primary
        >
          Apply
        </BitaButton>
        {data?.meta_reference_data && applyButtonValidations() && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '10px',
            }}
          >
            <BiSolidError className="icon-error" />
            <div>Cannot apply until errors are solved</div>
          </div>
        )}
        {data?.meta_reference_data && applyButtonValidations() && (
          <div className="tooltipErrorApply">
            {existRequestCoverage() && (
              <p style={{ display: 'flex' }}>
                {' '}
                <BiSolidError className="icon-error" /> The basket contains some non-covered
                instruments. Please review and make a decision.
              </p>
            )}
            {data?.meta_reference_data?.length === 0 && (
              <p style={{ display: 'flex' }}>
                {' '}
                <BiSolidError className="icon-error" />
                The basket contains no instruments.
              </p>
            )}
            {!launchDate && (
              <p style={{ display: 'flex' }}>
                {' '}
                <BiSolidError className="icon-error" />
                The launch date is missing.
              </p>
            )}
            {interestCashValid && (
              <p style={{ display: 'flex' }}>
                {' '}
                <BiSolidError className="icon-error" />
                Interest Rate on Cash is missing.
              </p>
            )}
            {(!data?.name || data?.name === '') && (
              <p style={{ display: 'flex' }}>
                {' '}
                <BiSolidError className="icon-error" />
                The Name is missing.
              </p>
            )}
            {launchDate && errorCalendar && (
              <p style={{ display: 'flex' }}>
                {' '}
                <BiSolidError className="icon-error" />
                The launch date is incorrect.
              </p>
            )}
            {validateData.map(item => (
              <div>
                {!validarData(data, [item]) && (
                  <p style={{ display: 'flex' }}>
                    {' '}
                    <BiSolidError className="icon-error" />{' '}
                    {`${validateDataLabel[item]} is missing.`}
                  </p>
                )}
              </div>
            ))}
            {!isDivisorOrStartingValueValid() && (
              <p style={{ display: 'flex' }}>
                {' '}
                <BiSolidError className="icon-error" /> Either the divisor or starting value is
                missing/invalid. Please provide one of the two.
              </p>
            )}
          </div>
        )}
      </div>

      {/* Success Modal */}
      <BitaModal isModalOpen={showModal} setModalState={cleanAllStates}>
        <div style={{ width: ' 600px ', minHeight: '300px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px 0',
            }}
          >
            <span onClick={cleanAllStates} style={styleClose}>
              &times;
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className={style.modalTextTitle}>Submission successfully completed!</div>
            <div style={{ display: 'flex', margin: '5%', fontSize: '20px' }}>
              <Logo style={{ width: '100px', height: '100px', marginRight: '20px' }} />
              <div>
                <p> Submission ID: {data?.id}</p>
                <p> Submission Status: Transmitted to BITA ACE </p>
                <p> Ticker: {data?.symbol}</p>
                {data?.starting_value && <p> Start Value: {data?.starting_value}</p>}
                {data?.currency && <p> Currency: {data?.currency}</p>}
              </div>
            </div>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}
          >
            <BitaButton primaryWhite onClick={() => history.push('/')} height="35px" width="200px">
              Go to Home
            </BitaButton>
            <BitaButton primaryWhite onClick={cleanAllStates} height="35px" width="200px">
              Submit another basket
            </BitaButton>
          </div>
        </div>
      </BitaModal>
      {/* Confirm Modal */}
      <BitaModal isModalOpen={showModalConfirm} setModalState={() => setShowModalConfirm(false)}>
        <div
          style={{
            width: '850px',
            minHeight: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              SUBMIT BASKET
            </h2>
            <span onClick={() => setShowModalConfirm(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px 0', textAlign: 'center' }}>
              {' '}
              Are you sure you want to submit these parameters?{' '}
            </div>

            {/* <div data-simplebar style={{ height: '360px' }}> */}
            <div data-simplebar style={{ height: '460px' }} className="previewSubmitBasket">
              <div
                style={{ padding: '0px 5px' }}
                // className={tableStyles.tableTitle}
              >
                Basket Data Preview
              </div>
              <div
                // className={tableStyles.tableContainer}
                style={{ width: '100%', padding: '0px 5px' }}
              >
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.tableNoMetadata}>
                    <ShowPreview
                      data={data}
                      templateData={templateData}
                      orgPrefix={orgPrefix}
                      launchDate={launchDate}
                    />
                  </div>
                </div>

                <div
                  // className={tableStyles.tableTitle}
                  style={{ marginTop: '3%' }}
                >
                  Constituents
                </div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <BasketTable
                    height="100%"
                    titleBasketTable={false}
                    deleteBasketTable={false}
                    buttonsBasketTable={false}
                  />
                  {/* <div className={style.tableNoMetadata}>
                    {Object.keys(data?.metadata?.awf ?? {}).map(item => (
                      <div>{item}</div>
                    ))}
                  </div> */}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => {
                  setButtonStateLoading({ ...buttonStateLoading, submit: true });
                  const formData = new FormData();
                  const updatedData = {
                    ...data,
                    org_prefix: orgPrefix ?? '',
                    additional_settings: {
                      ...data?.additional_settings,
                    },
                    withholding_tax_id:
                      !data?.withholding_tax_id ||
                      data?.withholding_tax_id === 'BITA Withholding Taxes'
                        ? null
                        : data?.withholding_tax_id,
                    launch_point:
                      data?.calculation_setup !== 'EOD_INTRADAY' ||
                      data.launch_point === 'MARKET_OPEN'
                        ? 'MARKET_OPEN'
                        : data.launch_point,
                    metadata: [...data?.meta_reference_data],
                    decrement_basket: {
                      annualized_daily_decrement: isActiveAnnualizedYearlyDecrement,
                      decrement_value_percentage: isActiveAnnualizedYearlyDecrement
                        ? Number(data?.percentage_value)
                        : 0,
                    },
                    divisor_determination_setup: data?.divisor_determination_setup?.replace(
                      ' ',
                      '_',
                    ),
                    divisor: data?.divisor,
                    launch_date: formatDateString(
                      data?.deployment_setup !== 'IMMEDIATELY' ? launchDate : new Date(),
                    ),
                    type: 'basket',
                    // type: type.includes('Bottom') ? 'global' : type.toLowerCase().replace('/', '_'),
                  }; // Add the 'type' property to the data object

                  // delete updatedData.cash_distribution; // Remove the "cash_distribution" key
                  delete updatedData.launch_point;
                  delete updatedData.region;
                  delete updatedData.request_coverage;
                  delete updatedData.full_coverage;
                  delete updatedData.percentage_value;
                  delete updatedData.meta_reference_data;
                  if (data?.calculation_setup !== 'EOD_INTRADAY') {
                    updatedData.calculation_setup = 'EOD';
                  }
                  if (data?.cash_constituent === null) {
                    delete data?.cash_constituent;
                  }

                  if (enableDivisorOrStartingValue.statusStarting && data?.starting_value) {
                    delete updatedData?.meta_reference_data?.divisor;
                  } else if (enableDivisorOrStartingValue.statusDivisor && data?.divisor) {
                    delete updatedData?.meta_reference_data?.divisor;
                  }
                  if (data.dissemination === 'bita_bloomberg') {
                    updatedData.bloomberg_dissemination_setup = data.bloomberg_dissemination_setup;
                  } else {
                    delete updatedData.bloomberg_dissemination_setup;
                  }
                  createBasket(token, updatedData)
                    .then(rsp => {
                      const handleSuccess = (responseData, backtestError) => {
                        if (backtestError) {
                          toast(
                            'Your basket has been processed successfully but your backtest has not been processed. Please contact support',
                          );
                        }
                        setShowModalConfirm(false);
                        setShowModal(true);
                        setData({ ...responseData, corporate_actions_methodology: 'BITA' });
                        if (inputBasketRef.current) inputBasketRef.current.value = '';
                        if (inputBacktestRef.current) inputBacktestRef.current.value = '';
                      };

                      if (filesBacktest) {
                        formData.append('template', filesBacktest);
                        uploadBacktest(token, formData, rsp.data?.data?.symbol)
                          .then(response => handleSuccess(rsp.data?.data, false))
                          .catch(error => {
                            handleSuccess(rsp.data?.data, true);
                          });
                      } else {
                        handleSuccess(rsp.data?.data);
                      }
                    })
                    .catch(error => {
                      toastInterceptor(error);
                    })
                    .finally(() => {
                      setButtonStateLoading({ ...buttonStateLoading, submit: false });
                    });
                }}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                disabled={buttonStateLoading.submit}
                autofocus
              >
                {buttonStateLoading.submit ? <BitaSpinner pointers /> : 'Submit'}
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalConfirm(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* SEDOL(S) NOT FOUND Modal */}
      <BitaModal
        isModalOpen={showSedolNotFoundModal}
        setModalState={() => setShowSedolNotFoundModal(false)}
      >
        <div style={{ width: '600px ', minHeight: '380px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              SEDOL(S) NOT FOUND
            </h2>
            <span onClick={() => setShowSedolNotFoundModal(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Sorry, no information was found for these Sedol(s). Please check your file or contact
              support.
            </div>

            <div data-simplebar style={{ height: '360px' }}>
              <div style={{ width: '100%' }}>
                <div>Sedol(s)</div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.tableNoMetadata}>
                    {sedolsList.map(item => (
                      <div className={style.requestCoverageEntry}>
                        <div>{item}</div>
                        {/* <div>{Object.values(item)[0]}</div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => setShowSedolNotFoundModal(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Accept
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* showModalRequestCoverage Modal */}
      <BitaModal
        isModalOpen={showModalRequestCoverage}
        setModalState={() => setShowModalRequestCoverage(false)}
      >
        <div style={{ width: '600px ', minHeight: '380px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              REQUEST COVERAGE
            </h2>
            <span onClick={() => setShowModalRequestCoverage(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              We will send to the organization&apos;s e-mail the request for the following
              constituents
            </div>

            <div data-simplebar style={{ height: '360px' }}>
              <div style={{ width: '100%' }}>
                <div>Constituents</div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                    <div className={style.tableNoMetadata}>
                      <div className={style.requestCoverageEntryModal}>
                        <>
                          {authOrgPrefix === 'UB' && <div>SEDOL</div>}
                          <div>ISIN</div>
                          <div>MIC</div>
                          {authOrgPrefix !== 'UB' && <div>SEDOL</div>}
                        </>
                      </div>
                      {(data?.meta_reference_data || []).map(item => {
                        if (item?.type !== 'with_coverage') {
                          return (
                            <div className={style.requestCoverageEntryModal}>
                              <>
                                {authOrgPrefix === 'UB' && <div>{item?.sedol ?? '-'}</div>}
                                <div>{item?.isin ?? '-'}</div>
                                <div>{item?.exchange ?? '-'}</div>
                                {authOrgPrefix !== 'UB' && <div>{item?.sedol ?? '-'}</div>}
                              </>
                            </div>
                          );
                        }
                        return '';
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => {
                  let body;

                  if (data?.identifier_type === 'sedol') {
                    const sedolsArray = data?.meta_reference_data?.filter(
                      item => item?.type !== 'with_coverage',
                    );
                    const sedols = sedolsArray?.map(coverage => {
                      return {
                        sedol: coverage?.sedol ?? null,
                        isin: coverage?.isin ?? null,
                        mic: coverage?.exchange ?? null,
                        type: coverage?.type ?? null,
                      };
                    });
                    body = {
                      user_email: user?.userdata?.email,
                      user_name: user.username,
                      company_name: user.company,
                      request_coverage: sedols,
                    };
                  }

                  if (data?.identifier_type === 'isin') {
                    const isinArray = data?.meta_reference_data?.filter(
                      item => item?.type !== 'with_coverage',
                    );
                    const isins = isinArray?.map(coverage => {
                      return {
                        sedol: coverage?.sedol ?? null,
                        isin: coverage?.isin ?? null,
                        mic: coverage?.exchange ?? null,
                        type: coverage?.type ?? null,
                      };
                    });
                    body = {
                      user_email: user?.userdata?.email,
                      user_name: user.username,
                      company_name: user.company,
                      request_coverage: isins,
                    };
                  }

                  setShowModalRequestCoverage(false);
                  coverageRequest(token, body)
                    .then(rsp => {
                      if (rsp.data.data.email_sent) {
                        setShowModalRequestCoverageSuccessful(true);
                      } else {
                        toast.error('We had a problem processing your request');
                      }
                      setShowModalRequestCoverage(false);
                    })
                    .catch(error => {
                      toast.error('We had a problem processing your request');
                    });
                }}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Submit
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalRequestCoverage(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* delete constituents modal Modal */}
      <BitaModal isModalOpen={showModalDelete} setModalState={() => setShowModalDelete(false)}>
        <div style={{ width: '600px ', minHeight: '380px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              Delete Constituents
            </h2>
            <span onClick={() => setShowModalDelete(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Do you want to remove these constituents?
            </div>

            <div data-simplebar style={{ height: '360px' }}>
              <div style={{ width: '100%' }}>
                <div>Constituents</div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.tableNoMetadata}>
                    <div className={style.requestCoverageEntry}>
                      <>
                        {authOrgPrefix === 'UB' && data?.identifier_type !== 'isin' && (
                          <div>SEDOL</div>
                        )}
                        <div>ISIN</div>
                        <div>MIC</div>
                        {authOrgPrefix !== 'UB' && data?.identifier_type !== 'isin' && (
                          <div>SEDOL</div>
                        )}
                        <div>COMPANY NAME</div>
                      </>
                    </div>
                    {(constituentsDelete || []).map(item => (
                      <div className={style.requestCoverageEntry}>
                        <>
                          {authOrgPrefix === 'UB' && data?.identifier_type !== 'isin' && (
                            <div>{item?.sedol ?? '-'}</div>
                          )}
                          <div>{item?.isin ?? '-'}</div>
                          <div>{item?.exchange ?? '-'}</div>
                          {authOrgPrefix !== 'UB' && data?.identifier_type !== 'isin' && (
                            <div>{item?.sedol ?? '-'}</div>
                          )}
                          <div>{item?.company_name ?? '-'}</div>
                        </>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => {
                  setShowModalDelete(false);
                  const arrayIdentifier = constituentsDelete.map(item => item.isin ?? item.sedol);
                  setData({
                    ...data,
                    meta_reference_data: [
                      ...data?.meta_reference_data?.filter(
                        item => !arrayIdentifier?.includes(item?.isin ?? item?.sedol),
                      ),
                    ],
                  });

                  /*   const deleteArray = (arr, elementos) => {
                    return arr.filter(item => !elementos.includes(item));
                  }; */

                  /* const updateData = () => {
                    setData(prevData => {
                      const newData = { ...prevData };
                      const newMetadata = { ...newData?.meta_reference_data };
                      const newCustomPrice = { ...newData?.custom_prices?.prices };
                      Object.keys(newMetadata).forEach(key => {
                        if (Array.isArray(newMetadata[key])) {
                          newMetadata[key] = deleteArray(newMetadata[key], constituentsDelete);
                        } else {
                          newMetadata[key] = { ...newMetadata[key] };
                          Object.keys(newMetadata[key]).forEach(subKey => {
                            if (constituentsDelete.includes(subKey)) {
                              delete newMetadata[key][subKey];
                              if (data.metadata.exchange[subKey] === 'CASH') {
                                delete newData.cash_constituent;
                              }
                            }
                          });
                        }
                      });
                      if (Object.keys(newCustomPrice).length > 0) {
                        Object.keys(newCustomPrice).forEach(subKey => {
                          if (constituentsDelete.includes(subKey)) {
                            delete newCustomPrice[subKey];
                            if (data.metadata.exchange[subKey] === 'CASH') {
                              delete newData.cash_constituent;
                            }
                          }
                        });

                        newData.custom_prices.prices = newCustomPrice;
                      }
                      newMetadata.divisor = newData.divisor;
                      newData.metadata = newMetadata;
                      const newRequestCoverage = newData?.request_coverage.filter(item => {
                        return !Object.keys(item)?.some(key => constituentsDelete.includes(key));
                      });

                      newData.request_coverage = newRequestCoverage;
                      return newData;
                    });
                  };
                  updateData(); */
                  setConstituentsDelete([]);
                }}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Delete
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalDelete(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* sucessful coverage request modal */}
      <BitaModal
        isModalOpen={showModalRequestCoverageSuccessful}
        setModalState={() => setShowModalRequestCoverageSuccessful(false)}
      >
        <div style={{ width: '600px ', minHeight: '130px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              REQUEST COVERAGE
            </h2>
            <span onClick={() => setShowModalRequestCoverageSuccessful(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Your request is being processed, we will contact you shortly.
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => setShowModalRequestCoverageSuccessful(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Close
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>
      <VisualizationModal
        templateData={[
          { withholding_tax_name: 'BITA Withholding Taxes', id: 'default', org_id: 'BITA' },
          ...templateData,
        ]}
        setModalState={setOpenWithholdingModal}
        updateTemplate={() => {
          getTemplates()
            .then(rsp => {
              const dataTax = [
                ...rsp?.data?.data,
                {
                  withholding_tax_name: 'BITA Withholding Taxes',
                  id: 'BITA Withholding Taxes',
                  custom: 'false',
                },
              ];
              setTemplateData(dataTax);
            })
            .catch(error => {
              toastInterceptor(error);
            });
        }}
        select={value => {
          handleChange('withholding_tax_id', value);
          getTemplates().then(rsp => {
            const dataTax = [
              ...rsp?.data?.data,
              {
                withholding_tax_name: 'BITA Withholding Taxes',
                id: 'BITA Withholding Taxes',
                custom: 'false',
              },
            ];
            setTemplateData(dataTax);
          });
        }}
        isModalOpen={openWithholingModal}
      />
      <AddInstrumentModal
        isOpen={showModalAdd}
        cashOption={!data?.meta_reference_data?.find(item => item?.isin?.includes('CASH'))}
        onClose={() => setShowModalAdd(false)}
        listInstruments={data?.meta_reference_data ?? []}
        listMic={listMIC}
        addInstrument={newInstrument => {
          setData(prev => {
            if (newInstrument.exchange === 'CASH') {
              return {
                ...prev,
                dissemination: prev?.dissemination ?? 'BITA Only',
                cash_constituent: { interest_rate_on_cash: null },
                meta_reference_data: [...(prev.meta_reference_data ?? []), newInstrument],
              };
            }
            return {
              ...prev,
              dissemination: prev?.dissemination ?? 'BITA Only',
              meta_reference_data: [...(prev.meta_reference_data ?? []), newInstrument],
            };
          });
          setErrorReferenceData(true);
        }}
      />
    </div>
  );
};

export default SubmitBasket;
